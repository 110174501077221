import React from "react"
import styles from "./footer.module.scss"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import PageFrame from "./page-frame"

const autoCopyright = () => {
	const startYear = 2016;
	const currentYear = new Date().getFullYear();
	if (startYear === currentYear) {
		return startYear;
	}
	if (startYear < currentYear) {
		return startYear + ' - ' + currentYear;
	}
	return currentYear;
}

export default ({
	showAbout = false,
	showTermsOfUse = false,
	showPrivacyPolicy = false,
	part = "main"
}) => {
	const intl = useIntl()
	return (
		<footer className={part==="buckmemo"?styles.ctr1:styles.ctr}>
		  <PageFrame mode={part==="buckmemo"?2:1}>
		    {showAbout && <Link to="/about" className={styles.about}><FormattedMessage id="about" /></Link>}
		    <div>&copy; {autoCopyright()} <FormattedMessage id="site.name" /></div>
		    {showTermsOfUse && <Link to="/buckmemo/terms-of-use" className={styles.link}><FormattedMessage id="terms_of_use.title" /></Link>}
		    {showPrivacyPolicy && <Link to="/buckmemo/privacy-policy" className={styles.link}><FormattedMessage id="privacy_policy.title" /></Link>}
		    <div className={part==="buckmemo"?styles.socialMedia1:styles.socialMedia}>
		      <a href={intl.formatMessage({ id: "fb_page_link" })}>
		        <img src="/img/fb.png" alt="Facebook" />
		      </a><a href={intl.formatMessage({ id: "twitter_link" })}>
		        <img src="/img/twitter.png" alt="Twitter" />
		      </a><a href="mailto:support@walker-one.com">
		        <img src="/img/email.png" alt="Email" />
		      </a>
		    </div>
		  </PageFrame>
		</footer>
	)
}