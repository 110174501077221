import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

const SEO = ({
	title,
	pathname,
	image,
	siteName
}) => {
	const intl = useIntl()
	const getRawUrl = () => {
		const prefix = `/${intl.locale}`
		var url = pathname || '/'
		if (url.startsWith(prefix)) {
			url = url.replace(prefix, "")
		}
		return url
	}
	return (
		<StaticQuery query={query} render={(
		{
		  site: {
		    siteMetadata: {
					siteUrl,
					iOSAppId,
					fbAppId,
					twitterUsername,
					defaultImage
				}
			}
		}) => {
			const seo = {
				url: `${siteUrl}${pathname || '/'}`,
				rawUrl: getRawUrl(),
				image: `${siteUrl}${image || defaultImage}`
			}
			return (
				<Helmet defer={false}>
					<html lang={intl.locale} prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb#" />
					<meta name="apple-itunes-app" content={"app-id="+iOSAppId} />
					<title>{title}</title>
					{["en", "de", "fr", "ja", "ko", "zh-Hans", "zh-Hant"].map(code =>
						<link key={code} rel="alternate" hreflang={code} href={`${siteUrl}/${code}${seo.rawUrl}`} />)}
					<link rel="alternate" hreflang="x-default" href={`${siteUrl}${seo.rawUrl}`} />
					<meta name="Description" content={intl.formatMessage({ id: "buckmemo.desc" })} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content={title} />
					<meta property="og:description" content={intl.formatMessage({ id: "buckmemo.desc" })} />
					<meta property="og:url" content={seo.url} />
					<meta property="og:site_name" content={intl.formatMessage({ id: siteName || "buckmemo.name" })} />
					<meta property="og:image" content={seo.image} />
					<meta property="og:locale" content={intl.formatMessage({ id: "page.locale" })} />
					<meta property="fb:app_id" content={fbAppId} />
					<meta name="twitter:card" content="app" />
					<meta name="twitter:site" content={twitterUsername} />
					<meta name="twitter:description" content={intl.formatMessage({ id: "buckmemo.desc" })} />
					<meta name="twitter:app:country" content={intl.formatMessage({ id: "buckmemo.country_code" })} />
					<meta name="twitter:app:name:iphone" content={intl.formatMessage({ id: "buckmemo.name" })} />
					<meta name="twitter:app:id:iphone" content={iOSAppId} />
					<meta name="twitter:app:name:ipad" content={intl.formatMessage({ id: "buckmemo.name" })} />
					<meta name="twitter:app:id:ipad" content={iOSAppId} />
				</Helmet>
			)
		}} />
	)
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  pathname: PropTypes.string,
  image: PropTypes.string,
  siteName: PropTypes.string
}

SEO.defaultProps = {
  title: null,
  pathname: null,
  image: null,
  siteName: null
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl: url
        iOSAppId
        fbAppId
        twitterUsername,
        defaultImage: image
      }
    }
  }
`